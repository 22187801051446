import React from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AppContext from '../../containers/App/app.context.js';
import AuthService from '../../services/auth.service.js';
import Cookies from 'js-cookie';
import UnauthorizedError from '../../api/error/Unauthorized.error';

class SigninManager extends React.Component {
    static contextType = AppContext;
    
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false,
            unauthorizedMessage: false
        };
    }

    handleFormSubmit = async (event) => {
        try {
            event.preventDefault();

            this.setState({loading: true});

            let res = await AuthService.signin(this.state.username, this.state.password);
            if (res?.data?.success) {
                const jwtHeaderPayload = Cookies.get('jwtHeaderPayload');

                if (jwtHeaderPayload) {
                    localStorage.setItem("jwtHeaderPayload", JSON.stringify(jwtHeaderPayload));
                    
                    const [header, payload] = jwtHeaderPayload.split('.');
                    let payloadDecrypted = JSON.parse(atob(payload)); // TODO: find alternative to atob function
                    localStorage.setItem("userId", payloadDecrypted.userId);
                    localStorage.setItem("username", payloadDecrypted.username);
                    localStorage.setItem("email", payloadDecrypted.email);
                    localStorage.setItem("displayName", payloadDecrypted.displayName);

                    this.context.currentUserId = payloadDecrypted.userId;

                    // redirect to app
                    this.setState({loading: false});
                    this.props.navigate('/');

                    if (this.props.handleSignin) {
                        this.props.handleSignin();
                    }
                }
                else {
console.debug('could not find access cookie')

                    // TODO: send message to user
                }
            }
            else {
                this.setState({loading: false});
            }
        }
        catch (ex) {
            if (ex instanceof UnauthorizedError) {
                this.setState({loading: false, unauthorizedMessage: true});
            }
            else {
                console.warn('error while signing in:', ex);
            }
        }
    }

    handleInputChange = (event) => {
        const {name, value } = event.target;
        this.setState({[name]: value});
    }

    render() {
        const { t } = this.props;

        return (
            <form id="signin" onSubmit={this.handleFormSubmit}>
                <div>
                    <label htmlFor='username'>{t('auth.signin.form.username.label', 'Username')}</label>
                    <input name='username' type='text' onChange={this.handleInputChange} value={this.state.username} autoFocus={true} autoComplete='username' />
                </div>
                <div>
                    <label htmlFor='password'>{t('auth.signin.form.password.label', 'Password')}</label>
                    <input name='password' type='password' onChange={this.handleInputChange} value={this.state.password} autoComplete='current-password' />
                </div>

                <button disabled={this.state.loading} type='submit'>
                    {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>{t('auth.signin.form.button', 'Sign in')}</span>
                </button>
                {
                    this.state.unauthorizedMessage &&
                    <span className='validationMessage'>{t('auth.signin.form.unauthorizedMessage', 'Credentials are not valid.')}</span>
                }

                <div>
                    <p>Don't have an account? <Link to='/signup'>Sign up</Link>.</p>
                </div>
            </form>
        );
    }
}

export default withTranslation()(function(props) {
    const navigate = useNavigate();

    return <SigninManager {...props} navigate={navigate} />;
});