import React from "react";
import { BusinessProcessService } from '../../services/businessProcess.service';
import Modal from '../Modal';

class NodeEditModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = NodeEditModal.getStateFromProps(props);
    }

    getNodeFromState = () => {
        const node = {
            id: this.state.nodeId,
            type: BusinessProcessService.getTypeStringFromTypeId(this.state.nodeTypeId),
            position: this.state.position,
            data: {
                label: this.state.dataLabel,
                ynposid: this.state.ynposid,
                externalUrl: this.state.externalUrl
            }
        }

        return node;
    }

    static getStateFromProps(props) {
        return {
            nodeId: props.element?.id ? props.element.id: 0,
            systemId: props.systemId ? props.systemId : 0,
            nodeTypeId: props.element?.type ? BusinessProcessService.getTypeIdFromTypeString(props.element.type) : 0,
            position: { x: (props.element?.xPos ? props.element.xPos : 0), y: (props.element?.yPos ? props.element?.yPos : 0) },
            dataLabel: props.element?.data?.label ? props.element.data?.label : '',
            showDeleteConfirm: false,
            ynposid: props.element?.data?.ynposid ? props.element?.data?.ynposid : 0,
            externalUrl: props.element?.data?.externalUrl ? props.element?.data?.externalUrl : '',
          };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (props.systemId && current_state.systemId !== props.systemId) {
          return NodeEditModal.getStateFromProps(props);
        }

        return null
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({[name]: value});
    }

    handleCancel = (e) => {
        this.setState({showDeleteConfirm: false});
        this.props.setShow(false);
        e.stopPropagation();
    }

    handleSave = (e) => {
        e.preventDefault();

        try {
            this.setState({showDeleteConfirm: false});
            const isModalVisible = false;
            this.props.setShow(isModalVisible);
            this.props.handleUpdate(this.getNodeFromState());
        }
        catch(ex) {
            console.log('Unhandled error in nodeEditModal.js. ' + ex)
        }
    }

    handleDeleteOnClick = (e) => {
        e.preventDefault();

        this.setState({showDeleteConfirm: true});
    }

    handleDeleteConfirmed = (e) => {
        this.setState({showDeleteConfirm: false});

        if (e.currentTarget.value === 'Yes') {
            if (this.props.handleDelete) {
                const isModalVisible = false;
                this.props.setShow(isModalVisible);
                this.props.handleDelete(parseInt(this.state.nodeId));
            } else {
                console.warn('The function handleDelete was not passed.')
                //TODO: display user level warning
            }
        }
    }

    render() {
        return (
            <Modal title='Edit Node' isOpen={this.props.show} dismiss={this.handleCancel} action={this.handleSave} actionText='Save'>
                <div className="formField">
                    <label>Label:</label>
                    <div className="field">
                        <textarea name="dataLabel" value={this.state.dataLabel} onChange={this.handleInputChange} />
                    </div>
                </div>
                <>
                { this.state.nodeTypeId==0 &&
                <div className="formField">
                    <label>External URL:</label>
                    <div className="field">
                        <input type="text" name="externalUrl" value={this.state.externalUrl} onChange={this.handleInputChange} />
                    </div>
                </div>
                }
                </>
                <>
                    { (this.state.nodeTypeId==2 || this.state.nodeTypeId==5) && 
                    <div className="formField radio"><label>Yes/No Label Position:</label>
                        <div className="field">
                            <div className="radio"><input type="radio" name="ynposid" value="1" onChange={this.handleInputChange} checked={this.state.ynposid == 1} /> No on right, yes at bottom</div>
                            <div className="radio"><input type="radio" name="ynposid" value="2" onChange={this.handleInputChange} checked={this.state.ynposid == 2} /> Yes on right, no at bottom</div>
                            { (this.state.nodeTypeId==2) &&
                            <>
                            <div className="radio"><input type="radio" name="ynposid" value="3" onChange={this.handleInputChange} checked={this.state.ynposid == 3} /> No on left, yes at bottom</div>
                            <div className="radio"><input type="radio" name="ynposid" value="4" onChange={this.handleInputChange} checked={this.state.ynposid == 4} /> Yes on left, no at bottom</div>
                            </>
                            }
                        </div>
                    </div> }
                </>
                <>
                    <div className="formField">
                        <label></label>
                        <div className="field">
                            <span className="delete"><a onClick={this.handleDeleteOnClick}>Delete this node</a></span>
                        </div>
                    </div>
                    {
                        this.state.showDeleteConfirm &&
                        <div className="formField">
                            <label></label>
                            <div className="field">
                                <span className="warn">Are you sure you want to delete this node?</span>
                                <input type='button' value='No' onClick={this.handleDeleteConfirmed}></input>
                                <input type='button' value='Yes' onClick={this.handleDeleteConfirmed}></input>
                            </div>
                        </div>
                    }
                </>
            </Modal>
        );
    }
}

export default NodeEditModal;