import React from 'react';
import ReactDOM from 'react-dom';
import HoverMenuHost from './HoverMenuHost';
import HoverMenuItem from './HoverMenuItem';

class HoverMenu extends React.Component {
    state = {
        isOpen: false,
        style: {
            //position: 'absolute',
            top: 0,
            left: 500
        }
    };
    
    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPos = ({left, top, height, width, right, position}) => {
        this.setState(prevState => ({style: {...prevState.style, left, top, position }}));
    }

    handleListMouseOut = (event) => {
        this.setState({isOpen: false});
    }

    handleHostMouseOut = (event) => {
        if(event.relatedTarget.localName != 'span' && event.relatedTarget.className != 'hoverMenuItem') {
            if(event.relatedTarget.localName != 'ul' && !event.relatedTarget.classList?.contains('hoverMenu')) {
                this.setState({isOpen: false});
            }
        }
    }

    handleMouseOver = (event) => {
        this.setState({isOpen: true});
    }

    render() {
        const { id, className, children } = this.props;
        const { isOpen, style } = this.state;
        const menuChildren = [];

        const inputChildren = children.map((child, childKey) => {
            if(child.type.displayName === HoverMenuHost.displayName) {
                return React.cloneElement(child, {mouseleave: this.handleHostMouseOut, mouseover: this.handleMouseOver, getPos: this.getPos, key: childKey});
            }
            else {
                menuChildren.push(React.cloneElement(child, {key: childKey}));
                return;
            }
        });

        const baseElement = React.createElement('ul', { id: id, className: className, style: style, onMouseLeave: this.handleListMouseOut }, menuChildren);

        return (
            <>
            {inputChildren}
            {
                isOpen && ReactDOM.createPortal(
                    baseElement,
                    document.querySelector(".stacking-context--menu")
                )
            }
            </>
        );
    }
}

export { HoverMenu, HoverMenuHost, HoverMenuItem };