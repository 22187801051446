import React from 'react';
import InteractiveList from '../InteractiveList';
import { InteractiveListAction } from '../InteractiveList/action.js';
import SystemInput from '../../model/SystemInput.js';
import SystemOutcome from '../../model/SystemOutcome.js';
import SystemIO from '../../model/SystemIO.js';

class IOList extends React.Component {
    constructor(props) {
        super(props);
    }

    handleModalAction = async (list, item, action) => {
        try {
            switch(action) {
                case InteractiveListAction.CREATE:
                    let createFunction = null;
                    switch(this.props.type) {
                        case SystemIO.TYPE_INPUT:
                            item.isImposed = (this.props.subType === SystemIO.SUBTYPE_IMPOSED_INPUT);
                            createFunction = SystemInput.createFromService;
                            break;
                        case SystemIO.TYPE_OUTCOME:
                            item.isUndesirable = (this.props.subType === SystemIO.SUBTYPE_UNDESIRABLE_OUTCOME);
                            createFunction = SystemOutcome.createFromService;
                            break;
                        default:
                            console.error(`Unexpected type in ${this.toString()}.handleAction (type='${this.props.type}').`);
                    }

                    if (createFunction) {
                        return await createFunction(item);
                    }

                    break;
                case InteractiveListAction.DELETE:
                    let deleteFunction = null;
                    switch(this.props.type) {
                        case SystemIO.TYPE_INPUT:
                            item.isImposed = (this.props.subType === SystemIO.SUBTYPE_IMPOSED_INPUT);
                            deleteFunction = SystemInput.delete;
                            break;
                        case SystemIO.TYPE_OUTCOME:
                            item.isUndesirable = (this.props.subType === SystemIO.SUBTYPE_UNDESIRABLE_OUTCOME);
                            deleteFunction = SystemOutcome.delete;
                            break;
                        default:
                            console.error(`Unexpected type in ${this.toString()}.handleAction (type='${this.props.type}').`);
                    }

                    if (deleteFunction) {
                        return await deleteFunction(item.id);
                    }

                    break;
                case InteractiveListAction.UPDATE:
                    let updateFunction = null;
                    switch(this.props.type) {
                        case SystemIO.TYPE_INPUT:
                            item.isImposed = (this.props.subType === SystemIO.SUBTYPE_IMPOSED_INPUT);
                            updateFunction = SystemInput.update;
                            break;
                        case SystemIO.TYPE_OUTCOME:
                            item.isUndesirable = (this.props.subType === SystemIO.SUBTYPE_UNDESIRABLE_OUTCOME);
                            updateFunction = SystemOutcome.update;
                            break;
                        default:
                            console.log(`Unexpected type in ${this.toString()}.handleAction (type='${this.props.type}').`);
                    }

                    if (updateFunction) {
                        updateFunction(item)
                        .then((updatedIOSystem) => {
                            if (updatedIOSystem && updatedIOSystem.id > 0) {
                                if (this.props.onItemUpdated) {
                                    this.props.onItemUpdated(updatedIOSystem);
                                }

                                if(this.props.onClose) {
                                    this.props.onClose();
                                }

                                // clear the form
                                this.setState(this.getDefaultState());
                            }
                            else {
                                console.log('Invalid result from SystemInput.update method.');
                            }
                        })
                        .catch((error) => {
                            console.log(`Unhandled error while attempting to run the updateFunction in ${this.toString()}.handleAction: ${error}`);
                        });
                    }

                    break;
                default:
                    console.log(`Unexpected action in ${this.toString()}.handleAction (action='${action}').`);
            }
        }
        catch(ex) {
            console.log(`Unhandled error in ${this.toString()}. ${ex}`);
        }
    }

    toString() {
        return 'IOList';
    }

    render() {
        return (
            <InteractiveList
                key={'IOList'+this.props.itemList.length} // TODO: change the props name to items
                {...this.props }
                className="IOList"
                items={this.props.itemList} // TODO: change the props name to items
                externalTag="div"
                externalTagClassName={this.props.className + " IOList"}
                onModalAction={this.handleModalAction}
                useStackingContextForHeaderHoverMenu={this.props.useStackingContextForHeaderHoverMenu!=='undefined' ? this.props.useStackingContextForHeaderHoverMenu : true}
                useStackingContextForItemHoverMenu={this.props.useStackingContextForItemHoverMenu!=='undefined' ? this.props.useStackingContextForItemHoverMenu : true}
            />
        );
    }
}

export default IOList;