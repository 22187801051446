import React, { Component, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import BusinessSystemEditModal from './editModal.js';
import BusinessSystemNewModal from './newModal.js';
import BusinessSystemModal from './modal.js';
import { BusinessSystemService } from '../../services/businessSystem.service';
import { HoverMenu, HoverMenuHost, HoverMenuItem } from '../HoverMenu/index.js';
import IOList from './IOList.js';
import FeedbackLoop from '../../model/FeedbackLoop';
import FeedbackLoopList from './FeedbackLoopList.js';
import FeedbackLoopCreateModal from './FeedbackLoopCreateModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faPlusSquare, faMinusSquare, faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';
import TenantContext from '../../containers/App/tenant.context.js';
import SearchField from '../../components/BusinessSystemSearch/searchField.js';
import SearchResultsModal from '../../components/BusinessSystemSearch/searchResultsModal.js';
import ParentButton from './parentButton.js';
import AimField from './aimField.js';

class BusinessSystem extends Component {
    constructor(props) {
        super(props);

        this.state = BusinessSystem.generateStateFromProps(props);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        // this redirects to the process page if isProcess gets changed to false
        if(props.systemId != 0 && props.isProcess != state.isProcess && state.isProcess) {
            newState.redirect = true;
        }

        if(props.systemId != state.systemId) {
            newState = BusinessSystem.generateStateFromProps(props);
        }

        return newState;
    }

    static generateStateFromProps(props) {
        return {
            showEditModal: false,
            showNewModal: false,
            searchResultsModalIsVisible: false,
            feedbackLoopCreateModalIsVisible: false,
            subsystemEditModalIsVisible: false,
            subsystemEditModalIndex: 0,
            subsystemModalIsVisible: false,
            subsystemModalIndex: 0,
            subsystemModalAction: null,
            insertNewBeforeCurrent: false,
            systemId: props.systemId,
            parentSystemId: props.parentSystemId,
            name: props.name,
            aim: props.aim,
            startingBoundary: props.startingBoundary,
            endingBoundary: props.endingBoundary,
            startingState: props.startingState,
            resultingState: props.resultingState,
            isProcess: props.isProcess ? props.isProcess : 0,
            subsystems: (props.subsystems ? props.subsystems : []),
            feedbackLoops: (props.feedbackLoops ? props.feedbackLoops : []),
            chosenInputs: (props.chosenInputs ? props.chosenInputs : []),
            imposedInputs: (props.imposedInputs ? props.imposedInputs : []),
            desirableOutcomes: (props.desirableOutcomes ? props.desirableOutcomes : []),
            undesirableOutcomes: (props.undesirableOutcomes ? props.undesirableOutcomes : []),
            redirect: false
        }
    }

    setShowEditModal = (isVisible) => {
        this.setState({...this.state, showEditModal: isVisible});
    }

    handleEditModalUpdate = (s, isVisible) => {
        this.setState({...this.state, ...s, showEditModal: isVisible});
    }

    setShowNewModal = (isVisible, relativeSystemId, insertNewBeforeCurrent) => {
        this.setState({showNewModal: isVisible, insertRelativeToSystemId: relativeSystemId, insertNewBeforeCurrent: insertNewBeforeCurrent});
    }

    handleNewModalUpdate = (s, isVisible, newSystem, insertBefore, relativeSystemId) => {
        // find the index of the system this new one was inserted before or after
        let insertAtIndex = 0;
        if (relativeSystemId) {
            insertAtIndex = this.state.subsystems.findIndex((currValue, index, arr) => {
                return currValue.systemId === relativeSystemId;
            });

            if (insertBefore && insertAtIndex >= 0) {
                //insertAtIndex = insertAtIndex - 1;
            }
            else {
                insertAtIndex = insertAtIndex + 1;
            }
        }
        else if (this.state.subsystems.length!=-1) {
            // put it at the end of the list
            insertAtIndex = this.state.subsystems.length;
        }

        // insert the new system into the subsystems array
        let subsystemsClone = this.state.subsystems.slice();
        subsystemsClone.splice(insertAtIndex, 0, newSystem);
        
        this.setState({showNewModal: isVisible, subsystems: subsystemsClone});
    }

    setFeedbackLoopCreateModalIsVisible = (isVisible) => {
        this.setState({feedbackLoopCreateModalIsVisible: isVisible});
    }

    setSubsystemModalIsVisible = (isVisible, action = null, index = 0) => {
        this.setState({subsystemModalIsVisible: isVisible, subsystemModalAction: action, subsystemModalIndex: (index ? index : 0)});
    }

    handleSubsystemModalDelete = (subsystemId, isVisible) => {
        if (subsystemId && subsystemId>0) {
            // now try to delete locally
            const newSubsystems = [];
            this.state.subsystems.forEach((originalSubsystem) => {
                if (originalSubsystem.systemId != subsystemId) {
                    newSubsystems.push(originalSubsystem);
                }
            });
            this.setState({subsystems: newSubsystems});

            this.setSubsystemModalIsVisible(false);
        }
    }

    handleSubsystemModalUpdate = (s, isVisible) => {
        const subsystemsClone = this.state.subsystems.slice();
        subsystemsClone[this.state.subsystemModalIndex] = {...s};
        this.setState({subsystems: subsystemsClone, subsystemModalIsVisible: isVisible});
    }

    SUBSYSTEM_MOVE_BACKWARD = 0;
    SUBSYSTEM_MOVE_FORWARD = 1;
    handleSubsystemMove = async (subsystemId, direction) => {
        let subsystem = this.state.subsystems.find((s) => s.systemId === subsystemId);
        const updatedSystems = await BusinessSystemService.move(this.state.systemId, subsystemId, subsystem.displaySequence, direction);

        await this.updateSubsystemDisplaySequence(updatedSystems);
    }

    updateSubsystemDisplaySequence = async (updatedSystems) => {
        const stateSubsystems = [];
        this.state.subsystems.forEach((originalSubsystem) => {
            const copiedOriginalSubsystem = {...originalSubsystem};
            updatedSystems.forEach(updatedSubsystem => {
                if (copiedOriginalSubsystem.systemId === updatedSubsystem.systemId) {
                    copiedOriginalSubsystem.displaySequence = updatedSubsystem.displaySequence;
                }
            });

            stateSubsystems.push(copiedOriginalSubsystem);
        });

        const sortedStateSubsystems = stateSubsystems.sort((a, b) => a.displaySequence - b.displaySequence);
        this.setState({subsystems: sortedStateSubsystems});
    }

    handleFeedbackLoopCreateModalUpdate = (o, isVisible) => {
        FeedbackLoop.create(o)
            .then((newFeedbackLoop) => {
                // update local list
                const newFeedbackLoops = [...this.state.feedbackLoops, newFeedbackLoop];
                this.setState({feedbackLoops: newFeedbackLoops, feedbackLoopCreateModalIsVisible: isVisible});

                // close the modal
                this.setFeedbackLoopCreateModalIsVisible(isVisible);
            })
            .catch((error) => {
                console.log('Error creating feedback loop: ' + error);

                // TODO: alert user
            });
    }

    handleFeedbackLoopListItemEdit = (event) => {
        //
    }

    handleFeedbackLoopListItemDelete = (event) => {
        //
    }

    searchResultsModalClose = () => {
        this.setState({searchResultsModalIsVisible: false, searchText: ''});
    }

    searchResultsModalOpen = (searchFieldValue) => {
        this.setState({searchResultsModalIsVisible: true, searchText: searchFieldValue});
    }

    render() {
        return (
            <div className="businessSystem">
                { 
                    this.state.redirect && <Navigate to={'/process/'+ this.props.systemId} replace={true}/>
                }
                <div className="header">
                    <HoverMenu className="hoverMenu headerHoverMenu">
                        <HoverMenuItem tipText='Edit' onClick={() => this.setShowEditModal(true)}>
                            <FontAwesomeIcon icon={ faPenSquare } />
                        </HoverMenuItem>
                        
                        <HoverMenuHost>
                            <h2 className="name">{this.state.name}</h2>
                        </HoverMenuHost>
                    </HoverMenu>
                    <BusinessSystemEditModal show={this.state.showEditModal} setShow={this.setShowEditModal} handleUpdate={this.handleEditModalUpdate}
                        systemId={this.state.systemId}
                        parentSystemId={this.state.parentSystemId}
                        name={this.state.name}
                        aim={this.state.aim}
                        startingBoundary={this.state.startingBoundary}
                        endingBoundary={this.state.endingBoundary}
                        startingState={this.state.startingState}
                        resultingState={this.state.resultingState}
                        isProcess={this.state.isProcess}>
                    </BusinessSystemEditModal>

                    <SearchField openResultsModal={this.searchResultsModalOpen} />
                    <SearchResultsModal isOpen={this.state.searchResultsModalIsVisible} title="Search Results" searchText={this.state.searchText} onDismiss={this.searchResultsModalClose} />

                    <ParentButton parentSystemId={this.props.parentSystemId} />

                    <AimField aim={this.state.aim} />
                </div>

                <div className="diagram">
                    <div className="startingState">&nbsp;</div>
                    <div className="systemDiagram">
                        <div className="diagramParts">
                        <div className="diagramPart systemInputs">
                            <h5>Inputs</h5>
                            <IOList
                                className="chosenInputs"
                                title="Chosen"
                                systemId={this.state.systemId}
                                itemList={this.props.chosenInputs}
                                type="input"
                                subType="chosen" />
                            <div className="divider">
                                <div className="dashedLine">&nbsp;</div>
                                &nbsp;
                            </div>
                            <IOList
                                className="imposedInputs"
                                title="Imposed"
                                systemId={this.state.systemId}
                                itemList={this.props.imposedInputs}
                                type="input"
                                subType="imposed"
                            />
                            <div className="spanOfInfluence">
                                <div className="arrow">&nbsp;</div>
                                <h5>Span of Influence</h5>
                            </div>
                        </div>
                        <div className="diagramPart systemProcesses">
                            <div className="feedbackLoop">
                                <FeedbackLoopList title="Feedback Loops" listItems={this.state.feedbackLoops} systemId={this.state.systemId} onHoverMenuItemEditClick={this.handleFeedbackLoopListItemEdit} onHoverMenuItemDeleteClick={this.handleFeedbackLoopListItemDelete} key={`${this.state.subsystems.length}-${this.state.feedbackLoops.length}`} />
                                <div className="redesignContainer">
                                    <div className="redesign">
                                        <span>Design and Re-design of System</span>
                                    </div>
                                    <div className="arrow1"></div>
                                    <div className="arrow2"></div>
                                </div>
                            </div>
                            <div className="process">
                                <HoverMenu className="hoverMenu headerHoverMenu" key={`${this.state.subsystems.length}-${this.state.feedbackLoops.length}`} useStackingContext={false}>
                                    <HoverMenuItem tipText='Add new' onClick={() => this.setShowNewModal(true, null)}>
                                        <FontAwesomeIcon icon={ faPlusSquare } />
                                    </HoverMenuItem>
                                    <HoverMenuHost>
                                        <h5>Conversion Processes</h5>
                                    </HoverMenuHost>
                                </HoverMenu>
                                <TenantContext.Consumer>
                                    { companyId => (
                                        <BusinessSystemNewModal show={this.state.showNewModal} setShow={this.setShowNewModal} handleUpdate={this.handleNewModalUpdate} insertBefore={this.state.insertNewBeforeCurrent} relativeSystemId={this.state.insertRelativeToSystemId} companyId={companyId} parentSystemId={this.state.systemId} />
                                    )}
                                </TenantContext.Consumer>
                                <ul className="subsystemList">
                                    {this.state.subsystems.map((s, index) => (
                                        <li key={s.systemId}>
                                            <HoverMenu className="hoverMenu">
                                                <HoverMenuItem tipText='Edit' onClick={() => this.setSubsystemModalIsVisible(true, BusinessSystemModal.ACTION_UPDATE, index)}><FontAwesomeIcon icon={ faPenSquare } /></HoverMenuItem>
                                                <HoverMenuItem tipText='Insert before' onClick={() => this.setShowNewModal(true, s.systemId, true)}><FontAwesomeIcon icon={ faCaretSquareLeft } /><FontAwesomeIcon icon={ faPlusSquare } /></HoverMenuItem>
                                                <HoverMenuItem tipText='Insert after' onClick={() => this.setShowNewModal(true, s.systemId, false)}><FontAwesomeIcon icon={ faPlusSquare } /><FontAwesomeIcon icon={ faCaretSquareRight } /></HoverMenuItem>
                                                <HoverMenuItem tipText='Move back' onClick={() => this.handleSubsystemMove(s.systemId, this.SUBSYSTEM_MOVE_BACKWARD)}><FontAwesomeIcon icon={ faCaretSquareLeft } /></HoverMenuItem>
                                                <HoverMenuItem tipText='Move forward' onClick={() => this.handleSubsystemMove(s.systemId, this.SUBSYSTEM_MOVE_FORWARD)}><FontAwesomeIcon icon={ faCaretSquareRight } /></HoverMenuItem>
                                                <HoverMenuItem tipText='Delete' onClick={() => this.setSubsystemModalIsVisible(true, BusinessSystemModal.ACTION_DELETE, index)}><FontAwesomeIcon icon={ faMinusSquare } /></HoverMenuItem>
                                                
                                                <HoverMenuHost index={index}>
                                                    <div className="systemIcon">
                                                        <Link to={"/" + (s.isProcess?"process":"system") + "/" + s.systemId} className={"name"}>{s.name}</Link>
                                                    </div>
                                                </HoverMenuHost>
                                            </HoverMenu>
                                            {
                                                s.isProcess===1 &&
                                                <div className="flowIcon">&nbsp;</div>
                                            }
                                            <div className="metricIcon">&nbsp;</div>
                                            <h5>Metrics</h5>
                                        </li>
                                    ))}
                                </ul>
                                <BusinessSystemModal
                                    className={this.state.subsystemModalAction}
                                    show={this.state.subsystemModalIsVisible}
                                    action={this.state.subsystemModalAction}
                                    setShow={this.setSubsystemModalIsVisible}
                                    handleDelete={this.handleSubsystemModalDelete}
                                    handleUpdate={this.handleSubsystemModalUpdate}
                                    companyId={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].companyId : "")}
                                    systemId={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].systemId : "")}
                                    parentSystemId={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].parentSystemId : "")}
                                    name={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].name : "")}
                                    aim={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].aim : "")}
                                    startingBoundary={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].startingBoundary : "")}
                                    endingBoundary={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].endingBoundary : "")}
                                    startingState={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].startingState : "")}
                                    resultingState={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].resultingState : "")}
                                    isProcess={(this.state.subsystems.length>0 ? this.state.subsystems[this.state.subsystemModalIndex].isProcess : false)}>
                                </BusinessSystemModal>
                            </div>
                            <div className="spanOfControl">
                                <div className="arrow">&nbsp;</div>
                                <h5>Span of Control</h5>
                                <div className="arrow end">&nbsp;</div>
                            </div>
                        </div>
                        <div className="diagramPart systemOutputs">
                            <h5>Outcomes</h5>
                            <IOList className="desirableOutputs" title="Desirable" 
                                systemId={this.state.systemId}
                                itemList={this.props.desirableOutcomes}
                                type="outcome"
                                subType="desirable"
                                useStackingContextForHeaderHoverMenu={false}
                                useStackingContextForItemHoverMenu={false} />
                            <div className="divider">
                                <div className="dashedLine">&nbsp;</div>
                                &nbsp;
                            </div>
                            <IOList className="undesirableOutputs" title="Undesirable" 
                                systemId={this.state.systemId}
                                itemList={this.props.undesirableOutcomes}
                                type="outcome"
                                subType="undesirable"
                                useStackingContextForHeaderHoverMenu={false}
                                useStackingContextForItemHoverMenu={false} />
                        </div>
                        </div>
                        <div className="boundaryStateArea">
                            <div className="boundaryArea">
                                <div className="startingBoundary boundaryStateAreaDivider">
                                    <span className="arrow">&nbsp;</span>
                                    <label>Boundary:</label>
                                    <span id="startingBoundaryField">{this.state.startingBoundary}</span>
                                </div>
                                <div className="endingBoundary boundaryStateAreaDivider">
                                    <label>Boundary:</label>
                                    <span id="endingBoundaryField">{this.state.endingBoundary}</span>
                                    <span className="arrow">&nbsp;</span>
                                </div>
                            </div>
                            <div className="stateArea">
                                <div className="startingStateDivider boundaryStateAreaDivider">
                                    <span className="arrow">&nbsp;</span>
                                    <label>Starting state:</label>
                                    <span id="startingStateField">{this.state.startingState}</span>
                                </div>
                                <div className="boundaryStateAreaDivider resultingStateDivider">
                                    <label>Resulting state:</label>
                                    <span id="resultingStateField">{this.state.resultingState}</span>
                                    <span className="arrow">&nbsp;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="resultingState">&nbsp;</div>
                </div>
            </div>
        );
    }
}

export default BusinessSystem;