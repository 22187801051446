import Api from '../api';
import UrlNotFoundError from '../api/error/UrlNotFound.error';

class FeedbackLoopService {
    static async create(feedbackLoopData) {
        const url = '/FeedbackLoop';
        try {
            // TODO: test for valid data

            const res = await Api.create(url, feedbackLoopData, null, FeedbackLoopService);
            return res;
        } catch(error) {
            if (error instanceof UrlNotFoundError) {
                throw `${FeedbackLoopService.toString()} request to endpoint was not found (url: ${url}).`;
            }
            else {
                throw `${FeedbackLoopService.toString()} unexpectedly failed in request to API (url: ${url}). ${error.message}`;
            }
        }
    }

    static async delete(id) {
        let result = null;

        const url = `/FeedbackLoop/${id}`;
        try {
            // TODO: test for valid ID

            result = await Api.delete(url, {id}, null, FeedbackLoopService);
        } catch(e) {
            throw `${FeedbackLoopService.toString()} unexpectedly failed in request to API (url: ${url}). ${e.message}`;
        }

        return result;
    }
    static async update(feedbackLoopData) {
        const {id} = feedbackLoopData;
        const url = `/FeedbackLoop/${id}`;
        try {
            // TODO: test for valid data
            if (id === null || id === 0) {
                throw new Error('${FeedbackLoopService.toString()}.update data did not contain a valid id.')
            }

            const res = await Api.update(url, feedbackLoopData, null, FeedbackLoopService);
            return res;
        } catch(error) {
            if (error instanceof UrlNotFoundError) {
                throw `${FeedbackLoopService.toString()} request to endpoint was not found (url: ${url}).`;
            }
            else {
                throw `${FeedbackLoopService.toString()} unexpectedly failed in request to API (url: ${url}). ${error.message}`;
            }
        }
    }

    static toString() {
        return 'FeedbackLoopService';
    }
}

export default FeedbackLoopService