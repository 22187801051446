import axiosInstance from './axiosSetup';
import UrlNotFoundError from './error/UrlNotFound.error';
import UnauthorizedError from './error/Unauthorized.error';

class Api {
    static async create(url, data, config, implementingObject) {
        try {
            // TODO: test for valid data

            const res = await axiosInstance.post(url, data, config);
            return res.data;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else {
                    console.log('unhandled error from api (status=' + error.response.status + ')');
                    console.log('data: ' + error.response.data);
                    console.log('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }

            // re-throw the error
            throw error;
        }
    }

    static async delete(url, data, config, implementingObject) {
        try {
            // TODO: test for valid data
            const res = await axiosInstance.delete(url, data, config);
            return res.data;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else {
                    console.log('unhandled error from API (status=' + error.response.status + ')');
                    console.log('data: ' + error.response.data);
                    console.log('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                throw `An unexpected error occurred while setting up the HTTP request to the API. (url: ${url}). Error details: ${error}`;
            }

            // re-throw the error
            throw error;
        }
    }

    static async uploadFile(url, file, config, implementingObject) {
        try {
            if (FileReader === undefined) {
                throw new Error('No file provided for upload.');
            }

            let fd = new FormData();
            fd.append('attachment', file, 'somePDFfile');

            const defaultConfig = {
                method: 'POST',
                body: fd
            };

            axiosInstance.post(url, fd, (config ? config : defaultConfig))
                .then((response) => {
                    if (response.data && response.data !== undefined) {
                        return response.data;
                    }
                    else {
console.debug('no data in response from uploadFile')
                    }
                })
                .catch((error) => {
                    throw error;
                });
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else {
                    console.debug('unhandled error from api (status=' + error.response.status + ')');
                    console.debug('data: ' + error.response.data);
                    console.debug('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }

            // re-throw the error
            throw error;
        }
    }

    static async signin(data, config, implementingObject) {
        const url = '/auth/signin';
        
        try {
            // TODO: test for valid data

            if (config) {
                config.data = data; // warning this will overwrite existing data if any
            }
            else {
                config = {data: data};
            }

            // pull JWT parts from localStorage
            const jwtHeaderPayload = localStorage.getItem('jwtHeaderPayload');
            const jwtSignature = localStorage.getItem('jwtSignature');

            config = {
                withCredentials: true,
                //headers: {
                //    cookie: `jwtHeaderPayload=${jwtHeaderPayload};jwtSignature=${jwtSignature}`
                //}
            };

            const res = await axiosInstance.post(url, data, config);
            return res;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else if (error.response.status === 401) {
                    throw new UnauthorizedError(url, error, (implementingObject || Api))
                }
                else {
                    console.warn('unhandled error from api (status=' + error.response.status + ')');
                    console.warn('data: ' + error.response.data);
                    console.warn('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }
            
            // re-throw the error
            throw error;
        }
    }

    static async signout(data, config, implementingObject) {
        const url = '/auth/signout';
        
        try {
            // TODO: test for valid data

            if (data) {
                if (config) {
                    config.data = data; // warning this will overwrite existing data if any
                }
                else {
                    config = {data: data};
                }
            }

            const res = await axiosInstance.post(url, data, config);

            return res;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else {
                    console.warn('unhandled error from api (status=' + error.response.status + ')');
                    console.warn('data: ' + error.response.data);
                    console.warn('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }
            
            // re-throw the error
            throw error;
        }
    }

    static async signup(data, config, implementingObject) {
        const url = '/auth/signup';
        
        try {
            // TODO: test for valid data

            if (config) {
                config.data = data; // warning this will overwrite existing data if any
            }
            else {
                config = {data: data};
            }

            // pull JWT parts from localStorage
            //const jwtHeaderPayload = localStorage.getItem('jwtHeaderPayload');
            //const jwtSignature = localStorage.getItem('jwtSignature');

            config = {
                withCredentials: true,
                //headers: {
                //    cookie: `jwtHeaderPayload=${jwtHeaderPayload};jwtSignature=${jwtSignature}`
                //}
            };

            const res = await axiosInstance.post(url, data, config);
            return res;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else if (error.response.status === 401) {
                    throw new UnauthorizedError(url, error, (implementingObject || Api))
                }
                else {
                    console.warn('unhandled error from api (status=' + error.response.status + ')');
                    console.warn('data: ' + error.response.data);
                    console.warn('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }
            
            // re-throw the error
            throw error;
        }
    }

    static async update(url, data, config, implementingObject) {
        try {
            // TODO: test for valid data
            const res = await axiosInstance.put(url, data, config);
            return res.data;
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else {
                    console.log('unhandled error from API (status=' + error.response.status + ')');
                    console.log('data: ' + error.response.data);
                    console.log('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                throw `An unexpected error occurred while setting up the HTTP request to the API. (url: ${url}). Error details: ${error}`;
            }

            // re-throw the error
            throw error;
        }

        return updateResult;
    }
}

export default Api;