import axiosInstance from './axiosSetup';

class SystemIOService {
    static async createInput(systemInputData) {
        const result = await this.createFromAPI('/SystemInput', systemInputData);
        return result;
    }

    static async createOutcome(systemOutcomeData) {
        const result = await this.createFromAPI('/SystemOutcome', systemOutcomeData);
        return result;
    }

    static async delete(itemId, action) {
        return this.deleteFromAPI(`${this.getBaseUrlForDelete(action)}/${itemId}`);
    }

    static getBaseUrlForDelete(action) {
        switch(action) {
            case "input":
                return "/SystemInput";
            case "outcome":
                return "/SystemOutcome";
            default:
                console.log('Unexpected action', action);
                return "";
        }
    }

    static async updateInput(systemInputData) {
        const result = await this.updateFromAPI(`/SystemInput/${systemInputData.id}`, systemInputData);
        return result;
    }

    static async updateOutcome(systemOutcomeData) {
        const result = await this.updateFromAPI(`/SystemOutcome/${systemOutcomeData.id}`, systemOutcomeData);
        return result;
    }

    static async createFromAPI(url, systemIOData) {
        try {
            // TODO: test for valid data

            const res = await axiosInstance.post(url, systemIOData);
            return res.data;
        } catch(e) {
            throw `${SystemIOService.toString()} createFromAPI unexpectedly failed in request to API (url: ${url}). Error details: ${e}`;
        }
    }

    static async deleteFromAPI(url) {
        try {
            // TODO: test for valid data

            const res = await axiosInstance.delete(url);
            return res.data;
        } catch(e) {
            throw `${SystemIOService.toString()} deleteFromAPI unexpectedly failed in request to API (url: ${url}). Error details: ${e}`;
        }
    }

    static async updateFromAPI(url, systemIOData) {
        try {
            // TODO: test for valid data

            const res = await axiosInstance.put(url, systemIOData);
            return res.data;
        } catch(e) {
            throw `${SystemIOService.toString()} updateFromAPI unexpectedly failed in request to API (url: ${url}). Error details: ${e}`;
        }
    }

    static toString() {
        return 'SystemIOService';
    }
}

export default SystemIOService