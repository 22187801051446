import axiosInstance from './axiosSetup';

function getConfig(subdomain) {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get('/getConfig', {
                params: {
                    //clientId: process.env.REACT_APP_CLIENT_ID
                    subdomain
                }
            })
                .then(function(response) {
                    resolve(response.data);
                });
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    });
    
}

export {
    getConfig
}