import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import { BusinessSystemService } from '../../services/businessSystem.service';

class SearchResultsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
            searchText: '',
            searching: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.searchText!=prevProps.searchText) {
            this.setState({searching: true});
            const params = {};
            params.text = this.props.searchText;
            BusinessSystemService.retrieveMultiple(params)
            .then((response) => {
                this.setState({searchResults: response, searchText: this.props.searchText, searching: false});
            })
            .catch((error) => {
                console.log('promise error caught', error)
            });
        }
    }

    render() {
        return (
            <Modal title={this.props.title} isOpen={this.props.isOpen} dismiss={this.props.onDismiss} showDismissButton={false} showActionButton={false}>
                <div>Search text: {this.props.searchText}</div>
                <div>Total results: {this.state.searching ? 'searching...' : this.state.searchResults.length}</div>
                {
                    this.state.searchResults.map((system, index, arr) => {
                        return (
                            <div key={system.systemId}>
                                <Link to={"/" + (system.isProcess?"process":"system") + "/" + system.systemId} className={"name"}>{system.name}</Link>
                            </div>
                        );
                    })
                }
            </Modal>
        );
    }
}

export default SearchResultsModal;