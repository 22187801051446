import React, { Component } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import AppContext from '../../containers/App/app.context.js';
import SigninManager from '../SigninManager';

export default class Authenticate extends Component {
    static contextType = AppContext;

    render() {
        const jwtHeaderPayload = Cookies.get('jwtHeaderPayload');
        if (jwtHeaderPayload) {
            // store the header.payload value from the cookie
            localStorage.setItem("jwtHeaderPayload", JSON.stringify(jwtHeaderPayload));

            // store payload values in local storage
            const [header, payload] = jwtHeaderPayload.split('.');
            let payloadDecrypted = JSON.parse(atob(payload)); // TODO: find alternative to atob function
            localStorage.setItem("userId", payloadDecrypted.userId);
            localStorage.setItem("email", payloadDecrypted.email);

            // set commonly used values in app context
            this.context.currentUserId = payloadDecrypted.userId;
            this.context.currentUserEmail = payloadDecrypted.email;

            return this.props.children;
        }
        else {
//temp disable for other dev
return this.props.children;
            localStorage.clear();

            let location = this.props.location ? this.props.location.pathname : null;
            return <Navigate to="/signin" state={{ from: location }} />;
        }
    }
}
