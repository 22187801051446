import React from 'react';
import { Outlet } from 'react-router-dom';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <h1>Apttool Web App
                        {
                            this.props.tenantName &&
                            <span className="Tenant-name"> - {this.props.tenantName}</span>
                        }
                    </h1>
                </header>

                <div id="App-content"><Outlet /></div>

                <div className="stacking-context stacking-context--menu"></div>
                <div className="stacking-context stacking-context--modal"></div>
            </div>
        );
    }
}

export default App;