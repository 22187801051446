import React from 'react';
import { HoverMenu, HoverMenuHost, HoverMenuItem } from '../HoverMenu/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPenSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

class List extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const ExternalTag = `${this.props.externalTag}`;

        let generatedListItems = this.props.listItems.map( (v, i) => (
            <li key={v.id}>
            <HoverMenu className="hoverMenu listItemHoverMenu" key={i} useStackingContext={this.props.useStackingContextForItemHoverMenu!=='undefined' ? this.props.useStackingContextForItemHoverMenu : true}>
                <HoverMenuItem tipText='Edit' onClick={this.props.onHoverMenuItemUpdateClick} data-id={v.id}>
                    <FontAwesomeIcon icon={ faPenSquare } />
                </HoverMenuItem>
                <HoverMenuItem tipText='Remove' onClick={this.props.onHoverMenuItemDeleteClick} data-id={v.id}>
                    <FontAwesomeIcon icon={ faMinusSquare } />
                </HoverMenuItem>
                <HoverMenuHost index={`${i}-${this.props.listItems.length}`}>
                    <div className="innerContainer">{v.name}</div>
                </HoverMenuHost>
            </HoverMenu>
            </li>
        ));

        let placeHolderFound = false;
        let allListItemElements = React.Children.map(this.props.children, (child, childKey) => {
            if (child) {
                if (child.type === 'div' && child.props.className === 'listPlaceholder') {
                    placeHolderFound = true;
                    return generatedListItems;
                }
                else {
                    return (
                        React.cloneElement(child, {key: childKey})
                    );
                }
            }
        });
        if (!placeHolderFound) {
            // the list placeholder was not included so attach generated items to end
            if (allListItemElements) {
                allListItemElements.concat(generatedListItems);
            }
            else {
                allListItemElements = generatedListItems;
            }
        }

        return (
            <ExternalTag className={this.props.externalTagClassName ? this.props.externalTagClassName : ""}>
                {
                    this.props.title &&
                    <HoverMenu className="hoverMenu headerHoverMenu" key={allListItemElements.length} useStackingContext={this.props.useStackingContextForHeaderHoverMenu!=='undefined' ? this.props.useStackingContextForHeaderHoverMenu : true}>
                        <HoverMenuItem tipText='Add new' onClick={this.props.onHoverMenuItemCreateClick}>
                            <FontAwesomeIcon icon={ faPlusSquare } />
                        </HoverMenuItem>
                        <HoverMenuHost>
                            <h5>{this.props.title}</h5>
                        </HoverMenuHost>
                    </HoverMenu>
                }
                <ul className={this.props.className}>
                    { allListItemElements }
                </ul>
            </ExternalTag>
        );
    }
}

export default List;