import React from 'react';
import Modal from '../Modal';

class FeedbackLoopCreateModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            systemId: (props.systemId ? props.systemId : 0),
            name: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.systemId != state.systemId) {
            return {systemId: props.systemId};
        }

        return null;
    }

    handleCancel = (e) => {
        this.props.setShow(false);
        this.setState(this.getDefaultState());
        e.stopPropagation();
    }

    handleCreate = (e) => {
        this.props.handleUpdate({id: this.state.id, systemId: this.state.systemId, name: this.state.name}, false);
        this.setState(this.getDefaultState());
        e.stopPropagation();
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({...this.state, [name]: value});
    }

    getDefaultState() {
        return {
            id: 0,
            systemId: 0,
            name: ''
        }
    }

    render() {
        return (
            <Modal title={this.props.title} isOpen={this.props.isVisible} dismiss={this.handleCancel} action={this.handleCreate} actionText='Create'>
                <div className="formField">
                    <label>Name:</label>
                    <input type="text" name="name" className="field" value={this.state.name} onChange={this.handleInputChange} autoFocus={true} />
                </div>
            </Modal>
        );
    }
}

export default FeedbackLoopCreateModal;