import React from 'react';
import List from '../List';
import { InteractiveListAction } from './action.js';
import InteractiveListItemModal from './modal.js';

class InteractiveList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            modalAction: null,
            modalIsVisible: false,
            modalItem: null
        };
    }

    componentDidMount() {
        this.setItems(this.props.items ? this.props.items : []);
        this.setState({modalItem: this.props.modalItem})
    }

    handleMenuItemActionClick = (event, action) => {
        let modalItem = null;

        if (action === InteractiveListAction.DELETE || action === InteractiveListAction.UPDATE) {
            // find the item to pass into the modal
            const itemId = event.target.dataset.id;
            modalItem = this.state.items.find((item) => {
                return item.id.toString() === itemId;
            });
        }
        if (action === InteractiveListAction.CREATE) {
            modalItem = {
                systemId: this.state.systemId,
                name: ''
            }
        }
        this.setState({modalItem: modalItem, modalAction: action});

        this.handleModalOpen(action);
    }

    handleModalAction = async (item, action) => {
        let modalActionResult = null;
        if (this.props.onModalAction) {
            modalActionResult = await this.props.onModalAction(this.state.items, item, action);
        }

        switch(action) {
            case InteractiveListAction.CREATE:
                if (modalActionResult) {
                    this.setItems(this.listItemAdd(this.state.items, item));

                    this.handleModalClose();
                }
                else {
                    throw new Error('Failed to create item.')
                }

                break;
            case InteractiveListAction.DELETE:
                if (modalActionResult===true) {
                    this.setItems(this.listItemRemove(this.state.items, item));

                    this.handleModalClose();
                }
                else {
                    throw new Error('Failed to delete item.')
                }

                return modalActionResult;
            case InteractiveListAction.UPDATE:
                this.setItems(this.listItemUpdate(this.state.items, item));

                this.handleModalClose();

                break;
        }
    }

    handleModalClose() {
        this.setState({modalItem: null, modalAction: null});
        this.setModalIsVisible(false);
    }

    handleModalDismiss = () => {
        this.setState({modalItem: null, modalAction: null});
        this.setModalIsVisible(false);
    }

    handleModalOpen(action) {
        this.setModalIsVisible(true, action);
    }

    listItemAdd(list, item) {
        let updatedList = [...list, item];
        return updatedList;
    }

    listItemRemove(list, item) {
        const matchIsItemToKeep = i => i.id != item.id;
        const updatedList = list.filter(matchIsItemToKeep);
        return updatedList;
    }

    listItemUpdate(list, item) {
        const matchIsItemToUpdate = i => i.id === item.id;
        const updatedList = list.map(listItem => {
            if (matchIsItemToUpdate(listItem)) {
                return { ...listItem, ...item};
            } else {
                return listItem
            }
        });
        return updatedList;
    }

    setItems(items) {
        this.setState({items});
    }

    setModalIsVisible(isVisible, action) {
        this.setState({modalAction: action, modalIsVisible: isVisible});
    }

    render() {
        return (
            <>
            <List
                className={(this.props.className ? this.props.className : '') + " List"}
                title={this.props.title}
                listItems={this.state.items}
                externalTag={this.props.externalTag?this.props.externalTag:"div"}
                externalTagClassName={this.props.externalTagClassName?this.props.externalTagClassName:this.props.className + " InteractiveList"}
                onHoverMenuItemCreateClick={(event) => this.handleMenuItemActionClick(event, InteractiveListAction.CREATE)}
                onHoverMenuItemDeleteClick={(event) => this.handleMenuItemActionClick(event, InteractiveListAction.DELETE)}
                onHoverMenuItemUpdateClick={(event) => this.handleMenuItemActionClick(event, InteractiveListAction.UPDATE)}
                useStackingContextForHeaderHoverMenu={this.props.useStackingContextForHeaderHoverMenu!=='undefined' ? this.props.useStackingContextForHeaderHoverMenu : true}
                useStackingContextForItemHoverMenu={this.props.useStackingContextForItemHoverMenu!=='undefined' ? this.props.useStackingContextForItemHoverMenu : true}
            >
                { this.props.children }
            </List>
            <InteractiveListItemModal
                className={this.state.modalAction===InteractiveListAction.DELETE?'delete':''}
                type={this.props.type}
                subType={this.props.subType}
                action={this.state.modalAction}
                systemId={this.props.systemId}
                isVisible={this.state.modalIsVisible}
                onAction={this.handleModalAction}
                onDismiss={this.handleModalDismiss}
                onItemCreated={this.handleItemCreated}
                onItemDeleted={this.handleItemDeleted}
                onItemUpdated={(item) => this.handleItemUpdated(item)}
                item={this.state.modalItem}
            />
            </>
        );
    }
}

export default InteractiveList;