import axiosInstance from './axiosSetup';

function getBusinessSystem(systemId) {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get('/System/'+systemId)
                .then(function(response) {
                    resolve(JSON.parse(JSON.stringify(response.data)));
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        if (error.response.status === 404) {
                            resolve(null);
                        }
                        else {
                            console.log(`Unhandled response status${error.response.status?" (" + error.response.status + "}":""} in getBusinessSystem: ${error.response.message}`)
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        resolve(null);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        resolve(null);
                    }
                
                });
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    });
    
}

function getBusinessSubsystems(systemId) {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get('/getBusinessSubsystems', {
                params: {
                    systemId
                }
            })
                .then(function(response) {
                    resolve(response.data);
                });
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    });
    
}

class BusinessSystemService {
    static update(systemId, name, aim, startingBoundary, endingBoundary, startingState, resultingState, isProcess) {
        let updateResult;

        const url = `/System/${systemId}`;
        try {
            // TODO: test for valid ID

            const system = {
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState,
                isProcess: isProcess
            };
            
            axiosInstance.put(url, system)
                .then(function(response) {
                    updateResult = response.data;
                })
                .catch(function(ex) {
                    //console.log('error: ' + JSON.stringify(ex));
                    throw ex;
                });
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        //if (!updateResult) {
        //    throw 'Error updating Business System';
       // }

        return updateResult;
    }

    static async create(companyId, parentSystemId, name, aim, startingBoundary, endingBoundary, startingState, resultingState, isProcess, insertBefore, relativeSystemId) {
        let createResult;

        const url = `/System`;
        try {
            // TODO: test for valid ID

            const system = {
                companyId: companyId,
                parentSystemId: parentSystemId,
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState,
                isProcess: isProcess
            };

            const data = {
                system: system,
                insertBefore: insertBefore,
                relativeSystemId: relativeSystemId
            }
            
            const res = await axiosInstance.post(url, data);
            const newSystem = res.data;

            return newSystem;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async retrieveMultiple(params) {
        let result = null;
        const url = `/Systems?text=${params?.text}`;

        try {
            // TODO: test for valid ID

           const response = await axiosInstance.get(url);
           result = response.data;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static async delete(systemId) {
        let result = null;

        const url = `/System/${systemId}`;
        try {
            // TODO: test for valid ID

           const response = await axiosInstance.delete(url);
           result = response.data;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static async move(systemId, subsystemId, subsystemdisplaySequence, direction) {
        const url = `/System/${systemId}/Subsystem/${subsystemId}/move`;
        const data = { subsystemDisplaySequence: subsystemdisplaySequence, direction: direction }
        const res = await axiosInstance.post(url, data);

        // TODO: handle result of move
        return Array.isArray(res.data)?res.data:[];
    }
}

export {
    getBusinessSystem,
    getBusinessSubsystems,
    BusinessSystemService
}