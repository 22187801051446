import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ReactFlow, { addEdge, applyNodeChanges, applyEdgeChanges, ReactFlowProvider, MarkerType } from 'reactflow';
import 'reactflow/dist/base.css';
import ProcessNodeStep from '../ProcessNodeStep';
import ProcessNodeStart from '../ProcessNodeStart';
import ProcessNodeDecision from '../ProcessNodeDecision';
import ProcessNodeEnd from '../ProcessNodeEnd';
import NodeEditModal from './nodeEditModal';
import NvaProcessNodeStep from '../ProcessNodeStep/nvaProcessNodeStep';
import NvaProcessNodeDecision from '../ProcessNodeDecision/nvaProcessNodeDecision';
import DndMenu from './dndMenu';
import RemovableEdge from '../RemovableEdge';
import ProcessContext from './processContext.js';
import BusinessProcessNode from '../../model/BusinessProcessNode';
import BusinessSystemEditModal from '../BusinessSystem/editModal.js';
import ProcessNodeMenu from '../ProcessNodeMenu';
import { HoverMenu, HoverMenuHost, HoverMenuItem } from '../HoverMenu/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';
import SiblingNavMenu from './siblingNavMenu.js';
import SearchField from '../../components/BusinessSystemSearch/searchField.js';
import SearchResultsModal from '../../components/BusinessSystemSearch/searchResultsModal.js';
import ParentButton from '../BusinessSystem/parentButton.js';
import AimField from '../BusinessSystem/aimField.js';

const edgeTypes = {
    default: RemovableEdge,
};

const nodeTypes = {
    default: ProcessNodeStep,
    startNode: ProcessNodeStart,
    decisionNode: ProcessNodeDecision,
    endNode: ProcessNodeEnd,
    nvaNodeDefault: NvaProcessNodeStep,
    decisionNodeNva: NvaProcessNodeDecision
};

class BusinessProcess extends Component {
    constructor(props) {
        super(props);

        this.state = BusinessProcess.generateStateFromProps(props);
    }

    static generateStateFromProps(props) {
        return {
            systemId: props.systemId,
            parentSystemId: props.parentSystemId,
            name: props.name,
            aim: props.aim,
            startingBoundary: props.startingBoundary,
            endingBoundary: props.endingBoundary,
            startingState: props.startingState,
            isProcess: props.isProcess ? props.isProcess : 0,
            siblingSystems: (props.siblingSystems ? props.siblingSystems : []),
            nodes: (props.nodes ? props.nodes : []),
            nodeConnections: props.nodeConnections ? props.nodeConnections.map(BusinessProcess.markerTypeV10Upgrades) : [],
            editModalIsVisible: false,
            selectedFlowElement: null,
            editModalIsVisible: false,
            editModalIndex: -1,
            reactFlowInstance: null,
            nodeMenuIsVisible: false,
            lastClickedNodePosition: {x: 0, y: 0},
            processEditModalIsVisible: false,
            redirect: false,
            searchResultsModalIsVisible: false,
            searchText: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        if(props.systemId != 0 && props.isProcess != state.isProcess && !state.isProcess) {
            newState.redirect = true;
        }

        if(props.systemId != state.systemId) {
            newState = BusinessProcess.generateStateFromProps(props);
        }
        else {
            if(props.siblingSystems != state.siblingSystems) {
                newState.siblingSystems = (props.siblingSystems ? props.siblingSystems : []);
            }

            if (props.nodes != state.nodes) {
                newState.nodes = (props.nodes ? props.nodes : []);
            }

            if (props.nodeConnections
                && props.nodeConnections != state.nodeConnections)
            {
                // temp markerType fix for v10 upgrade
                newState.nodeConnections = props.nodeConnections.map(BusinessProcess.markerTypeV10Upgrades);
            }
        }

        return newState;
    }

    static markerTypeV10Upgrades = (conn) => {
        if (conn.arrowHeadType) {
            // do we need to remove arrowHeadType?
            delete conn["arrowHeadType"];

            // convert to new v10 MarkerEnd format
            conn.markerEnd = { "type": MarkerType.ArrowClosed };
        }

        // ensure that identifiers are a string and not a number
        // TODO: edge objects should probably come through a data model in order to enforce reactflow requirements
        conn.id = conn.id.toString();
        conn.source = conn.source.toString();
        conn.target = conn.target.toString();

        // while we are here, might as well remove other unneeded fields
        // TODO: clean these fields up in the data model
        delete conn["sourceHandleId"];
        delete conn["targetHandleId"];

        return conn;
    }

    editModalSetShow = (isVisible) => {
        this.setState({editModalIsVisible: isVisible});
    }

    handleEdgeDelete = (event, edge) => {
        if (this.props.onNodeConnectionDelete) {
            this.props.onNodeConnectionDelete(edge?.id);
        } else {
            console.warn('The function onNodeConnectionDelete was not passed.');
        }
    }

    handleProcessEditModalUpdate = (systemStateFromModal, isVisible) => {
        this.setState({...systemStateFromModal, processEditModalIsVisible: isVisible});
    }

    handleFlowElementClick = (event, element) => {
        switch(event.detail) {
            case 1:
                // show original menu
                // temporarily disable context menu (original menu)
                //this.setState({selectedFlowElement: element, nodeMenuIsVisible: true});

                // NOTE: Disabling this code does not disable the context menu. You need to remove
                // the onNodeContextMenu attribute from the ReactFlow element.
                break;
            case 2:
                if (element) {
                    this.setState({selectedFlowElement: element, editModalIsVisible: true});
                }
                else {
                    console.error('No element received by handleFlowElementClick.')
                }
                break;
        }
    }

    handleFlowElementDelete = (nodeId, isModalVisible=false) => {
        this.editModalSetShow(isModalVisible);

        if (this.props.onNodeDelete) {
            this.props.onNodeDelete(nodeId);
        } else {
            console.warn('The function onNodeDelete was not passed.');
        }
    }

    handleFlowElementUpdate = (node) => {
        if (this.props.onNodeUpdate) {
            this.props.onNodeUpdate(node);
        }
    }

    // Shouldn't onConnect be wrapped into onEdgesChange in v11? The docs still show it active.
    onConnect = (connection) => {
        // notify parent of new node connection
        if (this.props.onNodeConnectionCreate) {
            this.props.onNodeConnectionCreate(connection);
        }
        else {
            throw new Error('onNodeConnectionCreate property is missing')
        }
    }

    onNodeContextMenu = (event, node) => {
        // Prevent native context menu from showing
        event.preventDefault();

        let x = node.position.x;
        if (node.type=='endNode' || node.type=='startNode') { x = x - 121}
        let y = node.position.y;
        if (node.type=='endNode' || node.type=='startNode') { y = y + 39}
        else if (node.type=='decisionNode' || node.type=='decisionNodeNva') { y = y + 56}
        this.setMenu({
            id: node.id,
            top: y, left: x,
            //type: node.type,
        });
    }

    onNodeMenuAttach = (nodeId) => {
        this.setMenu(null);
    }

    onNodeMenuCancel = () => {
        this.setMenu(null);
    }

    onNodesChange = (changes) => {
        // notify parent of node changes
        if (this.props.onNodesChange) {
            this.props.onNodesChange(applyNodeChanges(changes, this.state.nodes));
        }
    }

    onEdgesChange = (changes) => {
        // notify parent of edge changes
        if (this.props.onEdgesChange) {
            this.props.onEdgesChange(applyEdgeChanges(changes, this.state.nodeConnections));
        }
    }

    onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }

    onDrop = (event) => {
        event.preventDefault();

        let type = event.dataTransfer.getData('application/reactflow');

        // adjust type based on which column the node is dropped in
        const beginNvaColumn = 640;
        if (event.clientX > beginNvaColumn) {
            switch (type) {
                case 'default':
                    type = 'nvaNodeDefault';
                    break;
                case 'decision':
                case 'decisionNode':
                    type = 'decisionNodeNva';
                    break;
                default:
                    console.warn(`Unexpected node type in onDrop: '${type}'.`);
            }
        }

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
            return;
        }

        let position = {
            x: 0,
            y: 0
        }
        if (this.state.reactFlowInstance) {
            position = this.state.reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            // fix varying node shape offset issues
            // default type = 322 x 36
            let nodeWidth = 322;
            let nodeHeight = 36;
            switch (type) {
                case 'default':
                case 'nvaNodeDefault':
                    nodeWidth = 322;
                    nodeHeight = 36;
                    break;
                case 'decisionNode':
                case 'decisionNodeNva':
                    nodeWidth = 300;
                    nodeHeight = 72;
                    break;
                case 'startNode':
                case 'finishNode':
                case 'endNode':
                    nodeWidth = 58;
                    nodeHeight = 58;
                    break;
                default:
                    console.warn(`Unexpected node type: '${type}'. Assuming default size for offset fix.`);
            }            
            position.x = position.x - (nodeWidth / 2);
            position.y = position.y - (nodeHeight / 2);
        }

        const nodeTypeId = BusinessProcessNode.getTypeIdFromTypeString(type);
        const label = BusinessProcessNode.getLabelFromTypeId(nodeTypeId);
        const newNode = {
            systemId: this.props.systemId,
            type,
            position,
            data: { label },
        };

        // notify parent of new node
        if (this.props.onNodeCreate) {
            this.props.onNodeCreate(newNode);
        }
        else {
            throw new Error('onNodeCreate property is missing');
        }
    }

    onInit = (_reactFlowInstance) => this.setState({reactFlowInstance: _reactFlowInstance});

    handleNodeDragStart = (event, node) => {
        this.setState({lastClickedNodePosition: {x: event.clientX, y: event.clientY}});
    }

    handleNodeDragStop = (event, node) => {
        const threshold = 0;
        const {x, y} = this.state.lastClickedNodePosition;
        const hasBeenDragged = (Math.abs(event.clientX - x) > threshold || Math.abs(event.clientY - y) > threshold);

        if (hasBeenDragged) {
            this.handleFlowElementUpdate(node);
        }

        this.setState({lastClickedNodePosition: {x:0, y:0}});
    }

    setMenu = (menuElement) => {
        this.setState({menu: menuElement});
    }

    processEditModalSetShow = (isVisible) => {
        this.setState({processEditModalIsVisible: isVisible});
    }

    searchResultsModalClose = () => {
        this.setState({searchResultsModalIsVisible: false, searchText: ''});
    }

    searchResultsModalOpen = (searchFieldValue) => {
        this.setState({searchResultsModalIsVisible: true, searchText: searchFieldValue});
    }

    render() {
        return (
            <ProcessContext.Provider value={{handleFlowElementClick: this.handleFlowElementClick, handleEdgeDelete: this.handleEdgeDelete}}>
            { 
                this.state.redirect && <Navigate to={'/system/'+ this.props.systemId} replace={true}/>
            }
            <div className="businessProcess">
                <div className="header">
                    <SiblingNavMenu currentSystemId={this.state.systemId} siblingSystems={this.state.siblingSystems} />
                    <HoverMenu className="hoverMenu titleHoverMenu">
                        <HoverMenuItem tipText='Edit' onClick={() => this.processEditModalSetShow(true)}>
                            <FontAwesomeIcon icon={ faPenSquare } />
                        </HoverMenuItem>
                        
                        <HoverMenuHost>
                            <h2 className="name">{this.state.name}</h2>
                        </HoverMenuHost>
                    </HoverMenu>
                    <BusinessSystemEditModal show={this.state.processEditModalIsVisible} setShow={this.processEditModalSetShow} handleUpdate={this.handleProcessEditModalUpdate}
                        systemId={this.state.systemId}
                        name={this.state.name}
                        aim={this.state.aim}
                        startingBoundary={this.state.startingBoundary}
                        endingBoundary={this.state.endingBoundary}
                        //startingState={this.state.startingState}
                        //resultingState={this.state.resultingState}
                        isProcess={this.state.isProcess}>
                        
                    </BusinessSystemEditModal>

                    <SearchField openResultsModal={this.searchResultsModalOpen} />
                    <SearchResultsModal isOpen={this.state.searchResultsModalIsVisible} title="Search Results" searchText={this.state.searchText} onDismiss={this.searchResultsModalClose} />

                    <ParentButton parentSystemId={this.state.parentSystemId} />

                    <AimField aim={this.state.aim} />
                </div>
                <div className="boundaryArea">
                    <div className="boundaryAreaItem startingBoundary">
                        <label>Begin Boundary:</label>
                        <span id="startingBoundaryField">{this.state.startingBoundary}</span>
                    </div>
                    <div className="boundaryAreaItem endingBoundary">
                        <label>End Boundary:</label>
                        <span id="endingBoundaryField">{this.state.endingBoundary}</span>
                    </div>
                </div>
    <br/>
    <br/>
                <div className="diagram">
                    <ReactFlowProvider>
                        <DndMenu />
                        <div className="processDiagramHeaders">
                            <div>Alternate Process</div>
                            <div>Value Added Process</div>
                            <div>Non-Value Added Process</div>
                        </div>
                        <div className="processDiagram">
                            <ReactFlow
                                nodes={this.state.nodes}
                                edges={this.state.nodeConnections}
                                edgeTypes={edgeTypes}
                                nodeTypes={nodeTypes}
                                onInit={this.onInit}
                                onConnect={this.onConnect}
                                onNodesChange={this.onNodesChange}
                                onEdgesChange={this.onEdgesChange}
                                onDrop={this.onDrop}
                                onDragOver={this.onDragOver}
                                onNodeDragStart={this.handleNodeDragStart}
                                onNodeDragStop={this.handleNodeDragStop}
                                attributionPosition="bottom-left"
                                //onNodeContextMenu={this.onNodeContextMenu}
                                panOnDrag={false}
                                panOnScroll={false}
                                panOnScrollMode='horizontal'
                                zoomOnScroll={false}
                                zoomOnPinch={false}
                                zoomOnDoubleClick={false}
                                preventScrolling={false}
                            >
                                {this.state.menu &&
                                    <ProcessNodeMenu
                                        onClick={this.onPaneClick}
                                        onNodeAttach={this.onNodeMenuAttach}
                                        onNodeDelete={this.handleFlowElementDelete}
                                        onNodeMenuCancel={this.onNodeMenuCancel}
                                        {...this.state.menu}
                                    />
                                }
                            </ReactFlow>
                        </div>
                    </ReactFlowProvider>
                </div>
                <NodeEditModal show={this.state.editModalIsVisible} setShow={this.editModalSetShow}
                    key={this.state.selectedFlowElement?.id}
                    systemId={(this.props.nodes ? this.props.nodes[this.state.editModalIndex]?.systemId : "")}
                    name={(this.props.nodes ? this.props.nodes[this.state.editModalIndex]?.name : "")}
                    element={this.state.selectedFlowElement ? this.state.selectedFlowElement : undefined}
                    handleUpdate={this.handleFlowElementUpdate}
                    handleDelete={this.handleFlowElementDelete}>
                </NodeEditModal>
            </div>
            </ProcessContext.Provider>
        );
    }
}

export default BusinessProcess;