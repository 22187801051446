import axiosInstance from './axiosSetup';
import Api from '../api';
import UrlNotFoundError from '../api/error/UrlNotFound.error';

function getBusinessProcess(systemId) {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(`/Process/${systemId}`)
                .then(function(response) {
                    resolve(JSON.parse(JSON.stringify(response.data)));
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        if (error.response.status === 404) {
                            resolve(null);
                        }
                        else {
                            console.log('Unhandled response status in getBusinessProcess: ' + error.response.status)
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        resolve(null);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        resolve(null);
                    }
                
                });
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    });
    
}

function getBusinessProcessNodes(systemId) {
    return new Promise((resolve, reject) => {
        try {
            axiosInstance.get(`/Process/${systemId}/Nodes`)
                .then(function(response) {
                    resolve(response.data);
                });
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    });
    
}

class BusinessProcessService {
    //TODO: separate node methods into separate service
    static async createNode(nodeTypeId, systemId, position, data) {
        return BusinessProcessService.createNodeFromTypeId(systemId, nodeTypeId, position, data);
    }

    static async createNodeFromTypeString(systemId, typeString, position, data) {
        return BusinessProcessService.createNodeFromTypeId(systemId, this.getTypeIdFromTypeString(typeString), position, data);
    }

    static async createNodeFromTypeId(systemId, typeId, position, data) {
        const url = '/ProcessNode';
        try {
            // TODO: test for valid data
        
            const node = {
                systemId: systemId,
                nodeTypeId: typeId,
                position: position,
                data: data
            };

            const res = await Api.create(url, node, null, BusinessProcessService);
            res.type = BusinessProcessService.getTypeStringFromTypeId(typeId);
            return res;
        } catch(error) {
            if (error instanceof UrlNotFoundError) {
                throw `${BusinessProcessService.toString()} request to endpoint was not found (url: ${url}).`;
            }
            else {
                // unhandled error
                throw `${BusinessProcessService.toString()} unexpectedly failed in request to API (url: ${url}). ${error}`;
            }
        }
    }

    static async createNodeFromModel(node) {
        return BusinessProcessService.createNodeFromTypeId(node.systemId, node.typeId, node.position, node.data);
    }

    static async nodeAttachFile(nodeId, file) {
        const url = `/ProcessNode/${nodeId}/file`;
        try {
            // TODO: test for valid data

            Api.uploadFile(url, file, null, BusinessProcessService)
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    throw error;
                });
        }
        catch(ex) {
            if (ex instanceof UrlNotFoundError) {
                throw `${BusinessProcessService.toString()} request to endpoint was not found (url: ${url}).`;
            }
            else {
                // unhandled error
                throw `${BusinessProcessService.toString()} unexpectedly failed in request to API (url: ${url}). ${ex}`;
            }
        }
    }

    static toString() {
        return 'BusinessProcessService';
    }

    static async createNodeConnection(source, sourceHandle, target, targetHandle) {
        let createResult;

        const url = `/ProcessNodeConnection`;
        try {
            // TODO: test for valid ID

            const connection = {
                source: source,
                sourceHandle: sourceHandle,
                target: target,
                targetHandle: targetHandle
            };
 
            createResult = await axiosInstance.post(url, connection);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        //if (!createResult) {
        //    throw 'Error creating Business Process Node Connection';
       // }

       return createResult.data;
    }

    static update(systemId, name, aim, startingBoundary, endingBoundary) {
        var updateResult;

        const url = `/Process/${systemId}`;
        try {
            // TODO: test for valid ID

            const system = {
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState
            };
            
            axiosInstance.put(url, system)
                .then(function(response) {
                    updateResult = response.data;
                })
                .catch(function(ex) {
                    //console.log('error: ' + JSON.stringify(ex));
                    throw ex;
                });
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        //if (!updateResult) {
        //    throw 'Error updating Business System';
       // }

        return updateResult;
    }

    static async updateNode(nodeId, type, position, data) {
        let updateResult;

        const url = `/ProcessNode/${nodeId}`;
        try {
            // TODO: test for valid ID

            const node = {
                nodeId: nodeId,
                nodeTypeId: this.getTypeIdFromTypeString(type),
                position: position,
                data: data
            };
            
            axiosInstance.put(url, node)
                .then(function(response) {
                    updateResult = response.data;
                })
                .catch(function(ex) {
                    throw ex;
                });
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        //if (!updateResult) {
        //    throw 'Error updating Business Process Node';
       // }

        return updateResult;
    }

    static async deleteNode(nodeId) {
        let result = null;

        const url = `/ProcessNode/${nodeId}`;
        try {
            // TODO: test for valid ID

           const response = await axiosInstance.delete(url);
           result = response.data;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static deleteNodeConnection(nodeConnectionId) {
        let result = false;

        const url = `/ProcessNodeConnection/${nodeConnectionId}`;
        try {
            // TODO: test for valid ID
            
            axiosInstance.delete(url)
                .then(function(response) {
                    result = response.data;
                })
                .catch(function(ex) {
                    throw ex;
                });
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static getTypeIdFromTypeString(type) {
        // TODO: resolve why we have two different string values for most node types
        switch (type) {
            case 'default':
                return 0;
            case 'start':
            case 'startNode':
                return 1;
            case 'decision':
            case 'decisionNode':
                return 2;
            case 'finish':
            case 'finishNode':
            case 'endNode':
                return 3;
            case 'nvaNodeDefault':
                return 4;
            case 'decisionNodeNva':
                return 5;
            default:
                console.warn('Unrecognized node type string: ', type);
                return null;
        }
    }

    static getTypeStringFromTypeId(nodeTypeId) {
        switch (nodeTypeId) {
            case 0:
                return 'default';
                break;
            case 1:
                return 'startNode';
                break;
            case 2:
                return 'decisionNode';
                break;
            case 3:
                return 'endNode';
                break;
            case 4:
                return 'nvaNodeDefault';
                break;
            case 5:
                return 'decisionNodeNva';
            default:
                console.warn('Unrecognized node type Id: ', nodeTypeId);
                return 'default';
        }
    }
}

export {
    getBusinessProcess,
    getBusinessProcessNodes,
    BusinessProcessService
}