import axios from 'axios';
import SystemConfig from 'SystemConfig';

const apiDomain = SystemConfig.multitenant.apiDomain;
const apiPort = SystemConfig.multitenant.apiPort;
const apiUseUnsecuredRequest = (SystemConfig.multitenant.apiUseUnsecuredRequest &&
    SystemConfig.multitenant.apiUseUnsecuredRequest.toLowerCase() === 'true') | false;

var apiProtocol = 'http';
if (!apiUseUnsecuredRequest) {
    apiProtocol = apiProtocol + 's';
}

var baseUrl = `${apiProtocol}://${apiDomain}`;

if (apiPort && apiPort > 1025 && apiPort < 65535) {
    baseUrl = baseUrl.concat(`:${apiPort}`);
}

const instance = axios.create({
    baseURL: baseUrl
});

export default instance;