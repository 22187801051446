import React from 'react';
import InteractiveList from '../InteractiveList';
import { InteractiveListAction } from '../InteractiveList/action.js';
import FeedbackLoop from '../../model/FeedbackLoop';

class FeedbackLoopList extends React.Component {
    FILLER_ITEMS_MAX = 5;

    constructor(props) {
        super(props);

        this.state = {
            totalFillerItems: this.props.listItems ? this.getTotalFillerItems(this.props.listItems.length) : 0
        }
    }

    componentDidMount() {
        this.updateTotalFillerItems(this.props.listItems.length);
    }

    handleModalAction = async (list, item, action) => {
        switch(action) {
            case InteractiveListAction.CREATE:
                const newFeedbackLoop = await FeedbackLoop.create(item);
                if (newFeedbackLoop && newFeedbackLoop.id>0) {
                    item.id = newFeedbackLoop.id;
                    this.updateTotalFillerItems(list.length + 1);
                    return newFeedbackLoop;
                }
                break;
            case InteractiveListAction.DELETE:
                if (item.id > 0) {
                    const deleteResult = await FeedbackLoop.delete(item.id);
                    if (deleteResult===true) {
                        this.updateTotalFillerItems(list.length - 1);
                    }

                    return deleteResult;
                }
                else {
                    //TODO: raise error in modal
                    console.debug('item id was not valid. item=', item)
                }
                break;
            case InteractiveListAction.UPDATE:
                return await FeedbackLoop.update(item);
        }
    }

    updateTotalFillerItems = (listLength) => {
        this.setState({totalFillerItems: this.getTotalFillerItems(listLength)});
    }

    getTotalFillerItems(listLength) {
        return (listLength < this.FILLER_ITEMS_MAX) ? this.FILLER_ITEMS_MAX - listLength : 0;
    }

    render() {
        return (
            <InteractiveList key={'feedbackLoopList'+this.props.listItems.length} {...this.props } items={this.props.listItems} onModalAction={this.handleModalAction}>
                <li>&nbsp;</li>
                {
                    // add filler items if list is short
                    Array.apply(null, Array(this.state.totalFillerItems)).map(function(item, i){
                        return (
                            <li key={'filler' + i}>&nbsp;</li>
                        );
                    }, this)
                }
                <div className="listPlaceholder" />
                <li>&nbsp;</li>
            </InteractiveList>
        );
    }
}

export default FeedbackLoopList;